<div class="container">
  <div class="title">
    <h2 *ngIf="processors.length > 0">Paga desde cualquiera de estas billeteras digitales</h2>
  </div>

  @if(esResponsive$ | async ){
  <div class="processor-mobile">
    <ng-container *ngFor="let processor of processors">
      <img style="margin: 0.5rem;" [src]="processor.img" alt="">
    </ng-container>
  </div>

  }@else{
  <div class="selector-container">
    <div class="selector">
      <ng-container *ngFor="let processor of processors">
        <img [src]="processor.img" alt="">
      </ng-container>
    </div>
  </div>
  }
</div>