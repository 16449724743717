import { Injectable, WritableSignal, signal } from '@angular/core';
import html2canvas from 'html2canvas';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CaptureScreenService {

  generatedImage: WritableSignal<string> = signal<string>('');

  private readonly generatedImageSubject = new Subject<string>()
  generatedImage$: Observable<string> = this.generatedImageSubject.asObservable();

  constructor() { }

  captureScreen(itemId: string): void {
    const elementoACapturar = document.querySelector(`#${itemId}`) as HTMLElement;

    let stringBase64 = '';

    html2canvas(elementoACapturar).then(canvas => {
      const imagenBase64 = canvas.toDataURL('image/jpeg');

      stringBase64 = imagenBase64;

      if (stringBase64.length) {
        this.generatedImage.set(stringBase64)
        this.generatedImageSubject.next(stringBase64)
      }
    });
  }

  captureWithPromise(itemId: string): Promise<string> {
    return new Promise((resolve) => {
      const elementoACapturar = document.querySelector(
        `#${itemId}`
      ) as HTMLElement;

      let stringBase64 = '';

      html2canvas(elementoACapturar).then((canvas) => {
        const imagenBase64 = canvas.toDataURL('image/png');

        stringBase64 = imagenBase64;

        if (stringBase64.length) {
          resolve(stringBase64);
        }
      });
    });
  }
}
