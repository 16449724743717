import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionStepperComponent, ReadyButtonComponent } from '@angular-monorepo/shared';
import { MediaButtonComponent } from '@angular-monorepo/ui';
import { DirectConnectionsVoucher } from './direct-connections-voucher.interface';

@Component({
  selector: 'dcv-direct-connections-voucher',
  standalone: true,
  imports: [CommonModule, InstructionStepperComponent, MediaButtonComponent, InstructionStepperComponent, ReadyButtonComponent],
  templateUrl: './direct-connections-voucher.component.html',
  styleUrl: './direct-connections-voucher.component.scss',
})
export class DirectConnectionsVoucherComponent implements OnChanges {
  @Input({ required: true }) directConnectionsVoucherInput: DirectConnectionsVoucher
  @Input() color:string;
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['directConnectionsVoucherInput']) {
      this.directConnectionsVoucherInput = changes['directConnectionsVoucherInput'].currentValue;
    }
  }
}
