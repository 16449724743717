<div class="container">
  <div class="title">
    <h2>¡Todas tus redes de pago en un solo lugar! Selecciona tu canal favorito</h2>
  </div>
  <div class="selector-container">
    <div class="selector">
      @for (processor of processors; track $index) {
      <div [ngClass]="voucherPayin ? 'processor-voucher-payin' : 'processor'">
        @if(processor?.name === selectedProcessor) {
        <button (click)="selectProcessor(processor)" class="negative">
          <img class="processor-logo" [ngClass]="claseProcessor(processor)" [src]="parseSecondaryImage(processor.name)"
            [alt]="processor.name" />
        </button>
        }
        @if(processor?.name != selectedProcessor) {
        <button (click)="selectProcessor(processor)">
          <img class="processor-logo" [ngClass]="claseProcessor(processor)" [src]="parsePrimaryImage(processor.name)"
            [alt]="processor.name" />
        </button>
        }
      </div>
      }
    </div>
  </div>
</div>